var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mt-0",attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"pb-2 pt-2 pl-2 d-flex grey lighten-4"},[_c('span',{staticClass:"text-truncate"},[_vm._v(" "+_vm._s(_vm.$t("Failures"))+" ")]),_c('v-spacer')],1),_c('v-card-text',[_c('v-row',{staticClass:"mt-3",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-progress-linear',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],staticStyle:{"margin":"0px"},attrs:{"indeterminate":_vm.loading,"color":"primary"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","locale":"en","hide-default-footer":"","item-key":"_id","sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"multi-sort":"","page":_vm.page,"items-per-page":_vm.itemsPerPage,"server-items-length":_vm.totalResults,"search":_vm.search,"headers":_vm.headers,"items":_vm.records},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"update:page":function($event){_vm.page=$event},"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":'/cwe/' + item.id}},[_vm._v(" "+_vm._s(item.id)+" ")])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"px-0",attrs:{"colspan":headers.length}})]}},{key:"body.prepend",fn:function(ref){
var headers = ref.headers;
return [(!_vm.$vuetify.breakpoint.xs)?_c('tr',{staticStyle:{"border-bottom":"thin solid rgba(0, 0, 0, 0.12)"}},_vm._l((headers),function(header){return _c('td',{key:header.value,staticClass:"px-1"},[(
                    header.value != 'product_type' &&
                      header.value != 'vce_entries' &&
                      header.value != 'data-table-expand'
                  )?_c('div',{staticClass:"d-inline-flex",staticStyle:{"width":"100%"}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}]},[_vm._v(_vm._s(_vm.render))]),_c('v-text-field',{staticClass:"mb-1",staticStyle:{"width":"100%"},attrs:{"clearable":"","dense":"","hide-details":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.page = 1
                      _vm.getRecords()},"click:clear":function($event){_vm.filtersTM[header.value] = ''
                      _vm.page = 1
                      _vm.getRecords()}},model:{value:(_vm.filtersTM[header.value]),callback:function ($$v) {_vm.$set(_vm.filtersTM, header.value, $$v)},expression:"filtersTM[header.value]"}}),_c('v-btn',{staticClass:"mr-0 mt-1",attrs:{"icon":"","small":""},on:{"click":function($event){_vm.page = 1
                      _vm.getRecords()}}},[_c('v-icon',[_vm._v("mdi-magnify")])],1)],1):_vm._e()])}),0):_vm._e()]}}])})],1),_c('v-col',{staticClass:"mt-2 flex-wrap",attrs:{"cols":"12"}},[_c('div',{staticClass:"float-sm-left d-flex mb-2",staticStyle:{"width":"200px"}},[_c('v-select',{staticClass:"px-2 py-0 my-0 ",staticStyle:{"width":"85px"},attrs:{"items":_vm.rowsPerPageItems,"flat":"","hide-details":""},on:{"change":function($event){_vm.page = 1
              _vm.getRecords()}},model:{value:(_vm.itemsPerPage),callback:function ($$v) {_vm.itemsPerPage=$$v},expression:"itemsPerPage"}}),_c('span',{staticClass:"mt-2  text-body-1"},[_vm._v(" "+_vm._s((_vm.page - 1) * _vm.itemsPerPage + 1)+"-"+_vm._s(_vm.totalResults > _vm.page * _vm.itemsPerPage ? _vm.page * _vm.itemsPerPage : _vm.totalResults)+" "+_vm._s("of")+" "+_vm._s(_vm.totalResults)+" ")])],1),_c('v-pagination',{staticClass:"d-flex",attrs:{"length":Math.ceil(_vm.totalResults / _vm.itemsPerPage),"total-visible":"7"},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }